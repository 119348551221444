import React from 'react';
import SimpleLogo from '../../../assets/icons/logo-simple.svg';
import Gold from '../../../assets/images/gold.svg';
import Silver from '../../../assets/images/silver.svg';
import Bronze from '../../../assets/images/bronze.svg';

enum Places {
    first = 1,
    second,
    third,
}

type PrizeCardProps = {
    place: Places;
};

const placeDescriptions = [
    {
        suffix: 'st',
        color: 'brown-light',
        prize: 3000,
        prizeOutline: Gold,
    },
    {
        suffix: 'nd',
        color: 'silver',
        prize: 1000,
        prizeOutline: Silver,
    },
    {
        suffix: 'rd',
        color: 'bronze',
        prize: 500,
        prizeOutline: Bronze,
    },
];

export const PrizeCard: React.FC<PrizeCardProps> = ({place}) => {
    const {suffix, color, prizeOutline} = placeDescriptions[place - 1];
    return (
        <div className={`relative prize-card place-${place} flex flex-row py-8 xl:pb-0 pr-6 lg:mx-8 items-start w-full lg:max-w-lg xl:w-80 xl:h-80 mb-4 xl:mb-16`}>
            <img src={SimpleLogo}
                 alt="logo"
                 className="w-12 relative -left-6"
            />

            <div className={`text-${color} text-4xl`}>
                <h3>
                    {place}
                    <sup>
                        {suffix}
                    </sup>
                </h3>
                <h3>
                    place
                </h3>
            </div>

            <div className="flex-grow"/>

            <img src={prizeOutline} className="relative w-32 xl:self-end xl:top-16"/>
        </div>
    );
};
