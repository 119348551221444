import React, {Dispatch, MouseEventHandler, SetStateAction} from 'react';

type CodeAdminAuthProps = {
    password: string,
    setPassword: Dispatch<SetStateAction<string>>,
    submit: MouseEventHandler<HTMLButtonElement>,
};

export const CodesAdminAuth: React.FC<CodeAdminAuthProps> = ({password, setPassword, submit}) => {
    return (
        <div className="w-full flex flex-col items-center text-white font-DIN2014 p-8">
            <div className="container flex flex-col items-stretch">
                <div className="text-2xl mb-4">
                    Code Administration
                </div>
                <div className="flex flex-row items-stretch">
                    <input type="password"
                           placeholder="Password"
                           className="appearance-none border border-brown-light focus:border-white focus:outline-none bg-brown-dark p-4 text-base mr-4 flex-1 min-w-0"
                           value={password}
                           onChange={e => setPassword(e.target.value)}
                    />
                    <button type="button"
                            className="border border-brown-light hover:bg-white text-white font-bold hover:text-brown-light p-4"
                            onClick={submit}
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
};