import {useEffect, useMemo, useState} from 'react';
import FirstJuror from '../../assets/images/landing/1-josh-lamonaca.png';
import SecondJuror from '../../assets/images/landing/2-josh-o-p.png';
import ThirdJuror from '../../assets/images/landing/3-vadim-lastochkin.png';

export const useLanding = () => {
    const [focusedItem, setFocusedItem] = useState(0);
    const [scrollPos, setScrollPos] = useState(0);

    useEffect(() => {
        const handleScroll = () => setScrollPos(window.pageYOffset);

        window.addEventListener('scroll', handleScroll, {passive: true});
        return () => window.removeEventListener('scroll', handleScroll);
    }, [setScrollPos]);

    const jurors = useMemo(() => ([
        {
            title: 'Josh Lamonaca',
            subtitle: 'United Kingdom',
            imgUrl: FirstJuror,
            instagram: 'joshlamonaca',
        },
        {
            title: 'Josh O\'meara-Patel',
            subtitle: 'United Kingdom',
            imgUrl: SecondJuror,
            instagram: 'barber.josh.o.p',
        },
        {
            title: 'Vadim Lastochkin',
            subtitle: 'Russia',
            imgUrl: ThirdJuror,
            instagram: 'vadim_lastochkin',
        },
    ]), []);

    return {
        focusedItem,
        setFocusedItem,
        scrollPos,
        setScrollPos,
        jurors,
    };
};
