import React from 'react';
import HorizontalRuleImage from '../../../assets/images/landing/horizontal-rule.png';
import Scissors from '../../../assets/images/landing/scissors.png';

export const HorizontalRule: React.FC = () => (
    <div className="w-full my-4 flex items-center pl-2">
        <img src={Scissors}
             alt="scissors"
             className="z-10"
        />
        <img src={HorizontalRuleImage}
             alt="horizontal rule"
             className="w-full"
        />
    </div>
);
