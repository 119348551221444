import React from 'react';

export type CarouselItemProps = {
    imgUrl: string;
    title: string;
    subtitle: string;
    instagram: string;
    isFocused?: boolean;
};

export const CarouselItem: React.FC<CarouselItemProps> = ({imgUrl, title, subtitle, instagram, isFocused}) => (
    <a target="_blank" href={`https://www.instagram.com/${instagram}`}>
    <div className={'w-full md:w-56 flex flex-col mb-10 xl:mb-0 xl:ml-8 ' + (isFocused ? '' : 'opacity-50')}>
        <img src={imgUrl} alt="Carousel item" className="w-full"/>

        <div className="font-DIN2014 font-bold md:leading-9 mt-2 md:mt-8 text-base md:text-2xl">
            {title}
        </div>

        <div className="text-silver font-Gin underline hover:no-underline">
            @{instagram}
        </div>

        <div className="font-DIN2014 md:leading-9 md:mt-2 text-brown-light text-base md:text-2xl">
            {subtitle}
        </div>
    </div>
    </a>
);
