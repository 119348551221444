import React, {Ref, useState} from 'react';

import ValidIcon from '../../../assets/icons/signup/valid.svg';
import InvalidIcon from '../../../assets/icons/signup/invalid.svg';

export type InputProps = {
    value: string,
    setValue: (newValue: string) => void,
    placeholder: string,
    error?: string,
    touched: boolean,
    innerRef: Ref<HTMLDivElement>,
};

export const Input: React.FC<InputProps> = ({value, innerRef, setValue, placeholder, error: errorValue, touched}) => {
    const [hasFocus, setHasFocus] = useState(false);
    const error = touched ? errorValue : undefined;

    const icon = touched ? (errorValue ? InvalidIcon : (value && ValidIcon)) : undefined;
        const borderColor = hasFocus ? 'border-white' : (error ? 'border-red' : 'border-brown-light');

    return (
        <div ref={innerRef} className="pt-4 max-w-md flex flex-col">
            <div className={
                     'flex flex-row w-full border items-stretch ' +
                     borderColor
                 }>
                <input
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    type="text"
                    placeholder={placeholder}
                    className="flex-grow appearance-none bg-transparent border-none focus:outline-none pl-4 py-4"
                    onFocus={() => setHasFocus(true)}
                    onBlur={() => setHasFocus(false)}
                />
                <div className="flex flex-col items-center justify-center">
                    {icon && (
                        <img src={icon} className="px-5"/>
                    )}
                </div>
            </div>
            {error && (
                <div className="text-red text-sm mt-1.5">
                    {error}
                </div>
            )}
        </div>
    );
};
