import React from 'react';
import GreyLogo from '../../assets/images/logo-grey.svg';
import {Button} from './button';
import Scissors from '../../assets/images/landing/scissors.svg';
import {CarouselItem} from '../landing/ui/carousel';

export const Footer: React.FC = () => {
    return (
        <div className="w-full relative flex flex-col items-center px-2 xl:px-9">
            <div className="w-full xl:w-container max-w-full flex flex-col px-7 xl:px-0">
                <div className="flex flex-col sm:flex-row items-center w-full mt-30 mb-12 md:my-0 md:h-80">
                    <img src={GreyLogo} alt="Logo" className="h-32 mb-8 sm:mb-0 sm:mr-24"/>

                    <div className="font-DIN2014 text-silver text-sm sm:text-xl flex flex-col items-center sm:items-start">
                        <div className="font-bold">
                            Barber Battle Online
                        </div>
                        <div>
                            © 2022 All rights reserved
                        </div>
                    </div>

                    <div className="flex-grow h-6"/>

                    <div className="font-DIN2014 text-silver text-sm flex flex-row items-center sm:items-start">
                        <a className="mr-2 underline hover:no-underline"
                           href="/docs/terms.pdf"
                           target="_blank"
                        >
                            Terms
                        </a>
                        <a className="mr-2 underline hover:no-underline"
                           href="/docs/privacy.pdf"
                           target="_blank"
                        >
                            Privacy
                        </a>
                        <a className="underline hover:no-underline"
                           href="https://instagram.com/barberbattleonline/?utm_source=website&utm_content=barberbattleonline.com"
                           target="_blank"
                        >
                            Support
                        </a>
                    </div>

                </div>
            </div>
        </div>
    );
};
