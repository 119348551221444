import React from 'react';

import {Header} from '../ui/header';
import {TextContainer} from '../ui/text-container';

import {FileUpload} from './ui/file-upload';
import {Input} from './ui/input-field';

import {useSignUp} from './hooks';
import {Fields} from './fields';
import {FileUploads} from './file-uploads';
import BarberLogo from '../../assets/icons/landing/logo-white.svg';
import {Footer} from '../ui/footer';

export const Signup: React.FC = () => {
    const {
        fields,
        errors,
        touched,
        fileProgress,
        fileErrors,
        fileTouched,
        price,
        setField,
        setFile,
        onSubmit,
        ref,
    } = useSignUp();

    return (
        <div className="w-full bg-brown-darkest pb-10">
            <section>
                <div className="w-full signup pb-10 lg:px-36">
                    <div
                        className="flex flex-col items-stretch px-9 lg:px-32 xl:px-9 max-w-full">
                        <div
                            className="xl:px-32 my-11 xl:mt-0 xl:h-60 flex flex-row items-center justify-center xl:justify-start">
                            <a href="/">
                                <img className="w-36" src={BarberLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="px-10">
                        <h1 className="text-brown-light text-5xl">Let's get you in!</h1>
                        <TextContainer>
                            <p className="mt-4">
                                First, fill out your basic information in the following form.
                            </p>
                        </TextContainer>
                        <div className="font-DIN2014 text-white">
                            {Fields.map((field, index) => (
                                <Input key={`input-${index}`}
                                       innerRef={ref[field.name]}
                                       value={fields[field.name]}
                                       setValue={value => setField(field.name, value)}
                                       placeholder={field.placeholder}
                                       error={errors[field.name]}
                                       touched={touched[field.name]}
                                />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="px-10 pt-10 md:pb-10 w-full lg:px-36">
                    <h1 className="text-brown-light text-5xl lg:px-10">
                        upload your cut!
                    </h1>
                    <TextContainer>
                        <p className="mt-4 lg:px-10 max-w-2xl">
                            Use the buttons below to upload the photo(s) of the haircut you did.
                            One photo will absolutely do, but you're free to include more stuff if you want to.
                        </p>
                    </TextContainer>
                    <div className="lg:flex flex-wrap justify-start">
                        {FileUploads.map((uploadItem, index) => (
                            <FileUpload
                                key={`upload-${index}`}
                                innerRef={ref[uploadItem.name]}
                                id={uploadItem.id}
                                title={uploadItem.title}
                                type={uploadItem.type}
                                description={uploadItem.description}
                                setFile={file => setFile(uploadItem.name, file)}
                                progress={fileProgress[uploadItem.name]}
                                error={fileErrors[uploadItem.name]}
                                touched={fileTouched[uploadItem.name]}
                            />
                        ))}
                    </div>
                </div>

                <div className="px-10 pt-10 lg:px-44">
                    <button
                        className="font-DIN2014 font-bold text-xl mx-auto block bg-brown-light text-white pt-5 pb-4 md:pt-6 md:pb-5 w-full focus:outline-none hover:text-brown-light hover:border-white hover:bg-white"
                        onClick={onSubmit}
                    >
                        {price ? `CONFIRM & PAY ${price.toString()} EUR` : 'CONFIRM VIP ENTRY'}
                    </button>
                </div>
            </section>

            <Footer/>
        </div>
    );
};
