import {FormEvent, useEffect, useState} from 'react';
import axios from 'axios';
import {Config} from '../../config';

const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

export const useTeaser = () => {
    const [email, setEmail] = useState('');
    const [GDPRAgreed, setGDPRAgreed] = useState(false);
    const [inputWasClickedOn, setInputWasClickedOn] = useState(false);
    const [formStatus, setFormStatus] = useState('unsent');
    const [error, setError] = useState('');

    useEffect(() => setError(''), [email, GDPRAgreed]);

    const onNewsletterSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!email || !regex.test(email)) {
            setError('Please enter a valid email.');
            return;
        }
        if (!GDPRAgreed) {
            setError('You must agree with GDPR.');
            return;
        }


        if (formStatus === 'success')
            return;

        try {
            await axios.get(`${Config.NewsletterApiUrl}?mail=${encodeURIComponent(email)}`);
        } catch (e) {
            console.error(e);
        }

        setFormStatus('success');
    }

    return {
        email,
        setEmail,
        GDPRAgreed,
        setGDPRAgreed,
        inputWasClickedOn,
        setInputWasClickedOn,
        formStatus,
        onNewsletterSubmit,
        error,
    }
}