import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from '../../store';
import axios from 'axios';
import {Config} from '../../config';
import {FieldName} from './fields';
import {FileUploadName} from './file-uploads';
import {useHistory} from 'react-router';
import {uploadFile} from './drive-api';

export const PaymentForms = {
    Card: 'card',
    Paypal: 'paypal',
} as const;
export type PaymentForm = typeof PaymentForms[keyof typeof PaymentForms];

type State = {
    accessToken: string,
    signupSessionId: string,
    fields: Record<FieldName, string>,
    errors: Record<FieldName, string | undefined>,
    touched: Record<FieldName, boolean>,
    fileProgress: Record<FileUploadName, number | null>,
    fileErrors: Record<FileUploadName, string | undefined>,
    fileTouched: Record<FileUploadName, boolean>,
    scrollTo?: string,
};

const InitialState: State = {
    accessToken: '',
    signupSessionId: '',
    fields: {
        email: '',
        promoCode: '',
        country: '',
        instagramProfileName: '',
    },
    errors: {
        email: undefined,
        promoCode: undefined,
        country: undefined,
        instagramProfileName: undefined,
    },
    touched: {
        email: false,
        promoCode: false,
        country: false,
        instagramProfileName: false,
    },
    fileProgress: {
        mainPhoto: null,
        profileView: null,
        mainVideo: null,
        timelapse: null,
    },
    fileErrors: {
        mainPhoto: undefined,
        profileView: undefined,
        mainVideo: undefined,
        timelapse: undefined,
    },
    fileTouched: {
        mainPhoto: false,
        profileView: false,
        mainVideo: false,
        timelapse: false,
    },
} as const;

export const init = createAsyncThunk(
    'signup/init',
    async () => {
        let oldSignupSessionId = localStorage.getItem('signupSessionId');
        localStorage.setItem('price', '39');

        oldSignupSessionId = oldSignupSessionId ? `?signupSessionId=${oldSignupSessionId}` : '';

        let response = await axios.get(`${Config.ApiUrl}/create-signup-session${oldSignupSessionId}`);
        const {accessToken, signupSessionId} = response.data;

        return {
            accessToken,
            signupSessionId,
        };
    },
);

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isFilled = async (value: string) => value.length >= 2 ? undefined : 'Field is required';
const isEmail = async (value: string) => await isFilled(value) || (
    emailRegex.test(value) ? undefined : 'Please enter a valid e-mail'
);
export const isPromoCode = async (promoCode: string) => {
    const result = await axios.post(`${Config.ApiUrl}/validate-promo-code`, {promoCode: promoCode.toUpperCase()});

    localStorage.setItem('price', (result.data.price ?? 39).toString());

    if (result.data.isValid)
        return undefined;
    return result.data.message;
};
const isUploaded = (progress: number | null) => progress === null || progress === 100 ? undefined : 'File is uploading';

export const signup = createAsyncThunk(
    'signup/signup',
    async (history: ReturnType<typeof useHistory>, thunkApi) => {
        const state = thunkApi.getState() as AppState;

        const fields = SignupSelector.fields(state);
        const fileProgress = SignupSelector.fileProgress(state);
        const signupSessionId = SignupSelector.signupSessionId(state);

        thunkApi.dispatch(setTouched(Object.fromEntries(
            Object.entries(fields).map(([key]) => [key, true]),
        ) as typeof InitialState['touched']));

        const errors = [];

        let error: string | undefined = undefined;

        error = await isEmail(fields.email);
        if (error)
            errors.push(['email', error]);

        error = await isFilled(fields.country);
        if (error)
            errors.push(['country', error]);

        /*
                error = await isFilled(fields.instagramProfileName);
                if (error)
                    errors.push(['instagramProfileName', error]);
        */

        error = await isPromoCode(fields.promoCode);
        if (error)
            errors.push(['promoCode', error]);

        if (errors.length) {
            thunkApi.dispatch(setErrors({
                ...InitialState.errors,
                ...Object.fromEntries(errors),
            }));
        } else {
            thunkApi.dispatch(setErrors(InitialState.errors));
        }

        const fileErrors = Object.entries(state.signup.fileErrors)
            .filter(([, error]) => error !== undefined);

        if (fileProgress.mainPhoto !== 100)
            fileErrors.push(['mainPhoto', 'Main photo is required']);

        error = isUploaded(fileProgress.mainPhoto);
        if (error)
            fileErrors.push(['mainPhoto', error]);

        error = isUploaded(fileProgress.profileView);
        if (error)
            fileErrors.push(['profileView', error]);

        error = isUploaded(fileProgress.mainVideo);
        if (error)
            fileErrors.push(['mainVideo', error]);

        error = isUploaded(fileProgress.timelapse);
        if (error)
            fileErrors.push(['timelapse', error]);

        if (fileErrors.length) {
            thunkApi.dispatch(setFileErrors({
                ...state.signup.fileErrors,
                ...Object.fromEntries(fileErrors),
            }));
        } else {
            thunkApi.dispatch(setFileErrors(InitialState.fileErrors));
        }

        if (errors.length) {
            thunkApi.dispatch(setScrollTo(errors[0][0]));
            return;
        }
        else if (fileErrors.length) {
            thunkApi.dispatch(setScrollTo(fileErrors[0][0]));
            return;
        }

        try {
            await axios.post(`${Config.ApiUrl}/signup`, {
                ...fields,
                promoCode: fields.promoCode.toUpperCase(),
                signupSessionId,
            });

            history.push('/checkout');
        } catch (error) {
            const message = error.response.data.message;

            if (message)
                alert(message);
        }
    },
);

export const setFile = createAsyncThunk(
    'signup/setFile',
    async ({key, file}: { key: FileUploadName, file: File | null }, thunkAPI) => {
        const state = thunkAPI.getState() as AppState;

        thunkAPI.dispatch(setFileErrors({
            ...state.signup.fileErrors,
            [key]: undefined,
        }));

        const accessToken = SignupSelector.accessToken(state);
        const signupSessionId = SignupSelector.signupSessionId(state);

        const onProgressUpdate = (begin: number, size: number) => thunkAPI.dispatch(
            setFileProgress({
                key,
                progress: Math.round(begin * 100 / size),
            }),
        );

        if (file) {
            try {
                await uploadFile(
                    accessToken,
                    `${signupSessionId}-${key}`,
                    file,
                    onProgressUpdate,
                );
            } catch (_) {
                thunkAPI.dispatch(setFileErrors({
                    ...state.signup.fileErrors,
                    [key]: 'Upload failed, please try again',
                }));
            }

            return {key, progress: 100};
        } else {
            return {key, progress: null};
        }
    },
);

export const setField = createAsyncThunk(
    'signup/setField',
    async ({key, value}: { key: keyof State['fields'], value: string }, thunkAPI) => {
        const {signup: state} = thunkAPI.getState() as AppState;


        thunkAPI.dispatch(setFields({
            ...state.fields,
            [key]: value,
        }));
        thunkAPI.dispatch(setTouched({
            ...state.touched,
            [key]: true,
        }));

        let validate;
        switch (key) {
            case 'email':
                validate = isEmail;
                break;

            case 'country':
                validate = isFilled;
                break;

            case 'promoCode':
                validate = isPromoCode;
                break;
        }

        if (validate) {
            const error = await validate(value);

            thunkAPI.dispatch(setErrors({
                ...state.errors,
                [key]: error,
            }));
        }
    },
);

const signupSlice = createSlice({
    name: 'signup',
    initialState: InitialState,
    reducers: {
        setScrollTo: (state, action: PayloadAction<string | undefined>) => {
            state.scrollTo = action.payload;
        },
        setFields: (state, action: PayloadAction<typeof InitialState['fields']>) => {
            state.fields = action.payload;
            state.scrollTo = undefined;
        },
        setErrors: (state, action: PayloadAction<typeof InitialState['errors']>) => {
            state.errors = action.payload;
        },
        setTouched: (state, action: PayloadAction<typeof InitialState['touched']>) => {
            state.touched = action.payload;
        },

        setFileProgress: (state, action: PayloadAction<{ key: keyof State['fileProgress'], progress: number }>) => {
            state.fileProgress[action.payload.key] = action.payload.progress;
            state.scrollTo = undefined;
        },
        setFileErrors: (state, action: PayloadAction<typeof InitialState['fileErrors']>) => {
            state.fileErrors = action.payload;
        },
        setFileTouched: (state, action: PayloadAction<typeof InitialState['fileTouched']>) => {
            state.fileTouched = action.payload;
        },
    },
    extraReducers: builder =>
        builder
            .addCase(init.fulfilled, (state, action) => {
                state.accessToken = action.payload.accessToken;
                state.signupSessionId = action.payload.signupSessionId;
                localStorage.setItem('signupSessionId', state.signupSessionId);
            })
            .addCase(setFile.fulfilled, (state, action) => {
                state.fileProgress[action.payload.key] = action.payload.progress;
            }),
});

export const signupReducer = signupSlice.reducer;

export const setScrollTo = signupSlice.actions.setScrollTo;
export const setFields = signupSlice.actions.setFields;
export const setErrors = signupSlice.actions.setErrors;
export const setTouched = signupSlice.actions.setTouched;
export const setFileProgress = signupSlice.actions.setFileProgress;
export const setFileErrors = signupSlice.actions.setFileErrors;
export const setFileTouched = signupSlice.actions.setFileTouched;

export const SignupSelector = {
    scrollTo: (state: AppState) => state.signup.scrollTo,
    accessToken: (state: AppState) => state.signup.accessToken,
    signupSessionId: (state: AppState) => state.signup.signupSessionId,
    fields: (state: AppState) => state.signup.fields,
    errors: (state: AppState) => state.signup.errors,
    touched: (state: AppState) => state.signup.touched,
    fileProgress: (state: AppState) => state.signup.fileProgress,
    fileErrors: (state: AppState) => state.signup.fileErrors,
    fileTouched: (state: AppState) => state.signup.fileTouched,
};
export type SignupState = State;
