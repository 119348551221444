import {useDispatch, useSelector} from '../../hooks';
import {checkout, CheckoutSelector, init, PaymentForms, setPaymentForm} from './store';
import {useEffect} from 'react';

export const useCheckout = () => {
    const dispatch = useDispatch();

    const price = useSelector(CheckoutSelector.price);
    const loading = useSelector(CheckoutSelector.loading);
    const error = useSelector(CheckoutSelector.error);
    const stripe = useSelector(CheckoutSelector.stripe);
    const paymentForm = useSelector(CheckoutSelector.paymentForm);

    const signupSessionId = localStorage.getItem('signupSessionId')!;

    useEffect(() => {
        dispatch(init({signupSessionId}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isCheckoutDisabled = loading || !price;

    return {
        error,
        checkout: {
            disabled: isCheckoutDisabled,
            onClick: () => dispatch(checkout({stripe: stripe!, signupSessionId})),
            text: isCheckoutDisabled ? 'Loading...' : (price ? `CONFIRM & PAY ${price.toString()} EUR` : 'CONFIRM VIP ENTRY')
            ,
        },
        paymentForm: {
            card: {
                value: PaymentForms.Card,
                onChange: () => dispatch(setPaymentForm(PaymentForms.Card)),
                checked: paymentForm === PaymentForms.Card,
            },
            paypal: {
                value: PaymentForms.Paypal,
                onChange: () => dispatch(setPaymentForm(PaymentForms.Paypal)),
                checked: paymentForm === PaymentForms.Paypal,
            },
        },
    };
};
