import {AppDispatch, AppState} from './store';
import {TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector} from 'react-redux';
import {BreakpointWidth} from './constants';
import {useEffect, useState} from 'react';

export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useReduxSelector;
export const useIsDesktop = () => {
    const getIsDesktop = () => document.documentElement.clientWidth >= BreakpointWidth

    const [isDesktop, setIsDesktop] = useState(getIsDesktop());

    useEffect(() => {
        const eventHandler = () => setIsDesktop(getIsDesktop());

        window.addEventListener('resize', eventHandler, false);

        return () => window.removeEventListener('resize', eventHandler, false);
    });

    return isDesktop;
};

export const useClearLocalStorage = () => {
    useEffect(() => {
        localStorage.removeItem('signupSessionId');
    }, []);

    return {};
};
