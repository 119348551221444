import React from 'react';

type ButtonProps = React.ComponentProps<"a"> & {
    outline?: boolean,
}

export const Button: React.FC<ButtonProps> = ({outline, className, children, ...props}) => (
    <a className={
        'appearance-none block font-bold text-center border-2 font-DIN2014 text-base leading-none md:text-2xl uppercase pt-4 pb-3 md:pt-5 md:pb-4 hover:border-brown-light hover:bg-white hover:text-brown-light ' +
        (outline ? 'border-brown-light text-white ' : 'border-brown-light bg-brown-light text-white ') +
        className
    }
            {...props}
    >
        {children}
    </a>
);