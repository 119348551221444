import React from 'react';
import Logo from '../../assets/icons/landing/logo-white.svg';
import {useClearLocalStorage} from '../../hooks';

export const Canceled: React.FC = () => {
    useClearLocalStorage();

    return (
        <section className="h-screen w-full success flex flex-col items-center justify-center">
            <img src={Logo} alt="Logo"/>
            <h1 className="text-brown-light text-2xl md:text-5xl mt-16">
                Payment canceled.
            </h1>
        </section>
    );
};
