import React from 'react';
import Logo from '../../assets/icons/landing/logo-white.svg';
import LogoBulleit from '../../assets/images/logo-bulleit.svg';
import LogoBulleitSimple from '../../assets/images/teaser/bulleit-simple.png';
import BulleitBg from '../../assets/images/teaser/whiskey.png';
import BulleitBgSmall from '../../assets/images/teaser/whiskey-small.png';
import { HorizontalRule } from '../landing/ui/horizontal-rule';
import { Config } from '../../config';
import { Timer } from '../landing/ui/timer';
import { TextContainer } from '../ui/text-container';
import { useTeaser } from './hooks';
import { Footer } from '../ui/footer';
import TeaserVideo from '../../assets/videos/teaser-video.mp4';
import Fireworks1 from '../../assets/images/teaser/fireworks1.png';
import Fireworks2 from '../../assets/images/teaser/fireworks2.png';
import SimpleLogo from '../../assets/icons/logo-simple.svg';
import { useIsDesktop } from '../../hooks';
import SuccessIcon from '../../assets/icons/teaser/success.svg';

export const Teaser: React.FC = () => {
    const {
        email,
        setEmail,
        GDPRAgreed,
        setGDPRAgreed,
        inputWasClickedOn,
        setInputWasClickedOn,
        formStatus,
        onNewsletterSubmit,
        error,
    } = useTeaser();

    const isDesktop = useIsDesktop()

    return (
        <div className='w-full h-full' id="teaser">
            <div className='bg-brown-darkest absolute w-screen max-h-screen overflow-x-hidden'>
                <video src={TeaserVideo} autoPlay loop muted className='max-h-screen h-screen w-screen opacity-50 object-cover object-center'></video>
                <img src={Fireworks1} alt="fireworks" className='absolute top-8 w-64 left-8 hidden md:block' />
                <img src={Fireworks2} alt="fireworks" className='absolute w-48 md:top-1/2 md:right-8 md:w-64 top-20 -right-16' />
            </div>
            <div className='max-h-screen h-screen pb-20 flex flex-col justify-between items-center teaser-intro z-50 relative'>
                <div className='flex justify-center gap-24'>
                    <img src={Logo} alt='logo' className='w-28 hidden md:block' />
                    <img src={SimpleLogo} alt="logo" className='md:hidden fixed top-8 left-4' />
                    <img src={LogoBulleit} alt='Bulleit logo' className='w-28 md:w-48' />
                </div>
                <div className='px-9 lg:px-0'>
                    <h3 className='text-white lg:text-center text-xl mb-4 lg:text-4xl'>Barber Battle Online</h3>
                    <h1 className='text-brown-light font-bold text-7xl lg:text-8xl'>Are you ready?</h1>
                </div>
                <div>
                    <Timer dueOnDate={Config.CompetitionStart} leadText={'World\'s biggest barber competition starts in'} timerStyles='lg:-mt-16' />
                </div>
            </div>
            <div className='bg-brown-darkest text-white pt-16 flex flex-col items-center w-full'>
                <div className='mb-14 px-9 lg:px-0'>
                    <TextContainer>
                        <p className='lg:text-center mb-8'>
                            Barber Battle Online is the{' '}
                            biggest, baddest barber competition ever,
                            where the sharpest talents from all over the globe
                            fight for ultimate haircut domination.
                        </p>
                    </TextContainer>
                    <TextContainer>
                        <p className='lg:text-center'>
                            Don't miss out the best barber action in the world and
                            join us in this exciting online event.
                            Just leave us your e-mail and we'll remind you when it starts!
                        </p>
                    </TextContainer>
                </div>
                <form className='flex flex-col items-center font-DIN2014 lg:mb-32 mb-16 w-full px-9 lg:px-0'
                      onSubmit={onNewsletterSubmit}
                >
                    {formStatus === 'success' ? (
                        <img src={SuccessIcon} className="w-36 mt-16"/>
                    ) : (
                        <>
                            <div className="lg:w-2/5 w-full">
                                <input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="text"
                                    placeholder="name@email.com"
                                    className="border bg-transparent focus:outline-none focus:border-white w-full py-4 pl-4 mx-auto mb-5 text-2xl border-brown-light"
                                    onFocus={() => setInputWasClickedOn(true)}
                                />
                                <label htmlFor="gdrp" className="text-white mr-auto relative block">
                                    <input type="checkbox"
                                           name="gdrp"
                                           id="gdrp"
                                           className="appearance-none opacity-0 absolute left-0 top-0 bottom-0 "
                                           onChange={() => setGDPRAgreed(prev => !prev)}
                                    />
                                    <div className="flex mb-8">
                                <span
                                    className="border border-brown-light text-brown-light block h-7 w-7 text-center mr-4 select-none"
                                    dangerouslySetInnerHTML={{
                                        __html: GDPRAgreed ? '&#9632;' : '',
                                    }}
                                />
                                        <span className="select-none text-lg">I agree with <a href="#"
                                                                                              className="underline">GDPR terms</a></span>
                                    </div>
                                </label>
                            </div>
                            {error && (
                                <div className="mb-2 text-red">
                                    {error}
                                </div>
                            )}
                            <button
                                className="bg-brown-light focus:outline-none p-5 lg:w-80 w-full mx-auto uppercase font-bold text-2xl"
                                type="submit"
                            >
                                Remind me
                            </button>
                        </>
                    )}
                </form>
                <div className='w-full relative lg:mb-24 mb-36'>
                    <div className='md:w-3/4 w-full mx-auto'>
                        <HorizontalRule />
                    </div>
                    <img src={isDesktop ? BulleitBg : BulleitBgSmall} alt="" className='w-full h-96 max-h-96 object-cover image-fade-out self-end -mt-8' />
                    <div id='image-overlay' className='flex flex-col w-full justify-center absolute lg:top-32 top-72 items-center flex-wrap'>
                        <h1 className='md:text-6xl text-5xl text-brown-light mb-6'>Powered by</h1>
                        <img src={isDesktop ? LogoBulleitSimple : LogoBulleit} alt="" className='w-48 md:w-72 -mt-16 lg:-mt-0' />
                    </div>
                </div>
                <div className='lg:-ml-36 mb-28'>
                    <Footer />
                </div>
            </div>
        </div>
    );
};
