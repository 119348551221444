import React from 'react';
import {Header} from '../ui/header';
import {useCheckout} from './hooks';
/*
import VisaIcon from '../../assets/icons/checkout/visa-icon.png';
import MasterCardIcon from '../../assets/icons/checkout/mastercard-icon.png';
import PaypalIcon from '../../assets/icons/checkout/paypal-icon.png';
import {TextContainer} from '../ui/text-container';
*/
import VisaIcon from '../../assets/icons/checkout/responzivna-visa.svg';
import AppleIcon from '../../assets/icons/checkout/responzivny-applepay.svg';
import GoogleIcon from '../../assets/icons/checkout/responzivny-googlepay.svg';
import MastercardIcon from '../../assets/icons/checkout/responzivny-mastercard.svg';
import {useSelector} from '../../hooks';
import {CheckoutSelector} from './store';

export const Checkout: React.FC = () => {
    const {error, checkout, paymentForm} = useCheckout();
    const price = useSelector(CheckoutSelector.price);

    return (
        <div>
            <Header opacity={1}/>
            <section>
                <div className="checkout min-h-screen bg-brown-darkest pt-36 px-10">
                    <h2 className="text-5xl text-brown-light">
                        CONFIRM & PAY
                    </h2>

                    <p className="text-white font-DIN2014 font-bold my-6">
                        One thing left to do – pay your entrance fee of {price} EUR.
                    </p>

                    <div className="flex flex-col mb-20">
                        <label>
                            <input
                                type="radio"
                                name="payment"
                                className="hidden"
                                {...paymentForm.card}
                            />
                            <div className="flex font-DIN2014">
                                <div className="border border-brown-light text-brown-light flex-shrink-0 w-6 h-6 flex items-center justify-center">
                                    {paymentForm.card.checked && (
                                        <div className="bg-brown-light w-1.5 h-1.5"/>
                                    )}
                                </div>
                                <div className="ml-4">
                                    <p className="text-brown-light">
                                        Credit card
                                    </p>
                                    <p className="text-silver max-w-lg">
                                        You can pay comfortably through Strife – a safe and secure paygate that accepts any of the following payment methods:
                                    </p>
                                    <div className="flex items-center mt-4">
                                        <img src={AppleIcon}
                                             alt="Apple pay"
                                             className="h-6"
                                        />
                                        <img
                                            src={GoogleIcon}
                                            alt="Google pay"
                                            className="ml-4 h-6"
                                        />
                                        <img
                                            src={VisaIcon}
                                            alt="Visa"
                                            className="ml-4 h-6"
                                        />
                                        <img
                                            src={MastercardIcon}
                                            alt="MasterCard"
                                            className="ml-4 h-6"
                                        />
                                    </div>
                                </div>
                            </div>
                        </label>
{/*

                        <label className="my-4">
                            <input
                                type="radio"
                                name="payment"
                                className="hidden"
                                {...paymentForm.paypal}
                            />
                            <div className="flex font-DIN2014">
                                <span
                                    className="border border-brown-light text-brown-light block w-6 h-6 text-center"
                                    dangerouslySetInnerHTML={{
                                        __html: paymentForm.paypal.checked ? '&#9632;' : '',
                                    }}
                                />
                                <div className="ml-4">
                                    <p className="text-brown-light">
                                        Paypal
                                    </p>
                                    <img src={PaypalIcon}
                                         alt=""
                                    />
                                </div>
                            </div>
                        </label>
*/}
                    </div>

                    <button
                        className="font-DIN2014 font-bold text-xl mx-auto block bg-brown-light text-white py-4 w-full focus:outline-none mb-8"
                        role="link"
                        disabled={checkout.disabled}
                        onClick={checkout.onClick}
                    >
                        {checkout.text}
                    </button>

{/*
                    <TextContainer>
                        <p>
                            You can pay comfortably through Strife – a safe and secure paygate that accepts any of the following payment methods:
                        </p>
                    </TextContainer>
*/}

                    {error && (
                        <div className="text-red-700 mt-2">{error}</div>
                    )}
                </div>
            </section>
        </div>
    );
};
