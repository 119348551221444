import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {checkoutReducer} from './components/checkout/store';
import {signupReducer} from './components/signup/store';

export const AppStore = configureStore({
    reducer: {
        checkout: checkoutReducer,
        signup: signupReducer,
    },
});

export type AppState = ReturnType<typeof AppStore.getState>;
export type AppDispatch = typeof AppStore.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>;
