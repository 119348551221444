import React from 'react';

import {Footer} from '../ui/footer';

import {CarouselItem} from './ui/carousel';
import {PrizeCard} from './ui/prize-card';

import Scissors from '../../assets/images/landing/scissors.svg';
import {Timer} from './ui/timer';

import BarberLogo from '../../assets/icons/landing/logo-white.svg';
import BarberLogoSimple from '../../assets/icons/logo-simple.svg';
import CuttingHairImage from '../../assets/images/landing/cutting-hair.png';
import BulleitLogo from '../../assets/images/logo-bulleit.svg';
import Payment from '../../assets/images/landing/payment.svg';
import PaymentMobile from '../../assets/images/landing/payment-mobile.svg';
import HowItWorks1 from '../../assets/images/landing/how-it-works-1.svg';
import HowItWorks2 from '../../assets/images/landing/how-it-works-2.svg';
import HowItWorks3 from '../../assets/images/landing/how-it-works-3.svg';
import HowItWorks4 from '../../assets/images/landing/how-it-works-4.svg';
import CertDesktop from '../../assets/images/landing/cert-desktop.jpg';
import CertMobile from '../../assets/images/landing/cert-mobile.jpg';
import Badge from '../../assets/images/landing/badge.svg';

import {useLanding} from './hooks';
import {Prizes} from './prizes';
import {Button} from '../ui/button';

export const Landing: React.FC = () => {
    const {scrollPos, focusedItem, setFocusedItem, jurors} = useLanding();

    return (
        <>
            <div className="absolute w-full h-screen barber-tools-background">
                <div className="absolute left-0 right-0 bottom-0 h-20 overlay-y-gradient"/>
            </div>

            <div
                className="fixed -top-0.5 w-full h-24 md:h-32 bg-brown-dark z-20 flex xl:hidden flex-row pt-1 px-9 items-center justify-between transition duration-300 ease-in header"
                style={{opacity: scrollPos > 32 ? 1 : scrollPos / 32}}
            >
                <img className="h-12" src={BarberLogoSimple}/>
                <Button href="/signup"
                        className="w-36 md:w-64" outline>
                    Join now
                </Button>
            </div>

            <div className="relative w-full min-h-screen xl:min-h-0 flex flex-col items-center">
                <div className="w-full flex flex-row justify-evenly flex-wrap">
                    <div
                        className="flex flex-col items-stretch flex-shrink px-9 lg:px-32 xl:px-9 max-w-full sm:max-w-lg lg:max-w-full">
                        <div
                            className="xl:px-32 mt-11 xl:mt-0 xl:h-60 flex flex-row items-center justify-center xl:justify-start">
                            <img className="w-36" src={BarberLogo}/>
                        </div>

                        <div className="flex flex-col">
                            <div className="font-Gin uppercase text-white text-xl xl:text-4xl mt-7 xl:mt-20">
                                Barber Battle Online
                            </div>

                            <div className="font-GinRough uppercase text-brown-light text-6xl lg:text-8xl xl:mt-9">
                                Looking for <br className="hidden lg:inline"/>
                                the World's Best <br className="inline"/>
                                Haircut

                                <a target="_blank"
                                   href="https://www.bulleit.com/?utm_source=website&utm_content=barberbattleonline.com"
                                >
                                    <img src={BulleitLogo}
                                         className="relative inline xl:hidden h-24 -mt-8 -mb-8 ml-10"/>
                                </a>
                            </div>
                        </div>

                        <div className="mt-20 hidden xl:block">
                            <Button href="/signup"
                                    className="w-full xl:w-96 max-w-full">
                                Join now
                            </Button>
                        </div>
                    </div>

                    <div className="flex flex-col items-stretch px-9 w-full md:w-auto max-w-full">
                        <div className="h-60 flex flex-row items-center justify-center hidden xl:flex">
                            <Button href="/signup"
                                    className="w-60 max-w-full py-4" outline>
                                Join now
                            </Button>
                        </div>

                        <div className="flex flex-col items-center mt-10 xl:mt-38">
                            <Timer/>

                            <div className="font-Gin text-brown-light text-xl uppercase mt-10 hidden xl:block">
                                Powered by
                            </div>
                            <div className="-mt-10 hidden xl:block">
                                <a target="_blank"
                                   href="https://www.bulleit.com/?utm_source=website&utm_content=barberbattleonline.com"
                                >
                                    <img src={BulleitLogo}/>
                                </a>
                            </div>
                        </div>

                        <div className="flex flex-col items-center">
                            <Button href="/signup"
                                    className="w-full sm:w-96 max-w-xl xl:hidden mt-7">
                                Join now
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="mt-12 md:mt-32 w-full flex flex-col items-center px-9 sm:px-20">
                    <div className="w-full xl:w-container max-w-full flex flex-col">
                        <div className="font-DIN2014 text-base sm:text-xl lg:text-2xl text-white max-w-landing-text">
                            Barber Battle Online is the biggest, baddest barber competition in the world.
                            Pit your skills against the sharpest talents from all over the globe in a battle for
                            ultimate haircut domination. Are you ready to reach for the top, win amazing prizes
                            and reap fame?
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full relative flex flex-col items-center mt-10 md:mt-24 px-2 xl:px-9">
                <div className="absolute right-0 top-0 left-0 h-1/2 sm:hidden cutting-hair-image">
                    <div className="absolute left-0 bottom-0 right-0 overlay-y-gradient"/>
                </div>
                <div className="absolute right-0 top-0 bottom-0 hidden sm:block">
                    <div className="relative">
                        <img className="" src={CuttingHairImage}/>
                        <div className="absolute left-0 bottom-0 top-0 overlay-x-gradient"/>
                    </div>
                </div>

                <div className="relative w-full xl:w-container max-w-full flex flex-col px-7 xl:px-0 mt-56 md:my-36">
                    <div className="font-GinRough uppercase text-5xl md:text-7.5xl text-white">
                        Join the Elite<br/>
                        Barber Community
                    </div>
                    <div className="font-DIN2014 text-base md:text-2xl md:leading-9 text-white mt-8 md:mt-10 max-w-2xl">
                        By taking part in Barber Battle Online, you express the ambition to
                        rank among the world’s top barbers, and more! There’s cash prizes
                        for you to win and certificates to flex.
                    </div>
                </div>

                <div className="relative w-full xl:w-container-wide max-w-full mt-10 md:mt-0 mb-4 separator-container">
                    <div className="w-full flex flex-row items-center relative h-10 md:h-20 pl-8">
                        <img className="absolute left-0 h-10 md:h-20" src={Scissors}/>
                        <div className="relative flex-grow separator"/>
                    </div>
                </div>
            </div>

            <div className="w-full relative flex flex-col items-center px-2 xl:px-9">
                <div className="w-full xl:w-container max-w-full flex flex-col px-7 xl:px-0 mt-8 md:mt-16">
                    <div className="font-GinRough uppercase text-5xl md:text-7.5xl text-brown-light">
                        Prizes and <br className="hidden md:inline"/>
                        Certificates
                    </div>
                    <div className="flex flex-col sm:flex-row">
                        <div
                            className="font-DIN2014 text-base md:text-2xl md:leading-9 text-white mt-8 md:mt-10 md:max-w-xl">
                            The three sharpest guys in the game get money, plain
                            and simple. On top of that, everyone gets a certificate,
                            proving how they did.
                        </div>
                        <div className="flex-grow"/>
                        <img className="hidden lg:block" src={Payment}/>
                    </div>
                    <div
                        className="flex flex-col lg:flex-row flex-wrap items-center justify-evenly xl:justify-between mt-8 md:mt-24">
                        {[1, 2, 3].map((place) => (
                            <PrizeCard place={place} key={`${place}-place`}/>
                        ))}
                    </div>
                    <div
                        className="font-DIN2014 text-base md:text-2xl lg:leading-9 flex flex-wrap text-white w-full mt-4 md:mt-16">
                        {Prizes.map((prize, index) => (
                            <div className={`w-1/2 md:w-64 py-4`}
                                 key={`prize-${index}`}
                            >
                                <p>
                                    {prize.from}
                                    <sup>th</sup>
                                    {prize.to ? (
                                        <>
                                            -{prize.to}
                                            <sup>th</sup>
                                        </>
                                    ) : (
                                        <>
                                            <sup>+</sup>
                                        </>
                                    )}{' '}
                                    place:
                                </p>
                                <p>{prize.prize}</p>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col items-center mt-4">
                        <img className="lg:hidden" src={PaymentMobile}/>
                    </div>
                </div>
            </div>

            <div className="w-full relative flex flex-col items-center px-2 xl:px-9">
                <div
                    className="w-full xl:w-container max-w-full flex flex-row items-stretch px-7 xl:px-0 mt-8 md:mt-16 relative box-border">
                    <img src={CertDesktop}
                         className="absolute right-0 top-0 h-full w-auto mr-7 xl:mr-0 hidden md:block"
                    />
                    <img src={Badge}
                         className="absolute right-0 mr-3 bottom-0 right-0 -mb-8 w-48 mr-7 xl:mr-0 hidden md:block"
                    />

                    <div className="flex flex-col relative">
                        <div className="font-GinRough uppercase text-5xl md:text-7.5xl text-brown-light">
                            In addition to<br/>
                            Main Prizes
                        </div>
                        <div className="relative mt-7 md:hidden">
                            <img src={CertMobile}/>
                            <div className="absolute left-0 right-0 bottom-11 flex items-center justify-center">
                                <img src={Badge} className="w-2/5"/>
                            </div>
                        </div>
                        <div
                            className="font-DIN2014 text-base md:text-2xl md:leading-9 text-white mt-3 md:mt-6 md:max-w-xl">
                            You can get high quality certificate for various categories.
                            Ready to hang it on the wall in your barber shop to show everyone
                            that you are the best barber they can have.
                        </div>
                        <div className="font-DIN2014 text-base md:text-2xl md:leading-9 text-white mt-9 md:mt-6 md:max-w-xl">
                            Certificate categories:
                        </div>
                        <div
                            className="flex flex-col md:flex-row md:justify-between flex-wrap md:max-w-xl font-DIN2014 text-base md:text-2xl md:leading-9 text-white md:max-w-xl text-brown-light font-bold">
                            <div className="flex flex-col items-start flex-grow">
                                <div className="mt-4">
                                    Classic
                                </div>
                                <div className="mt-4">
                                    Colour
                                </div>
                                <div className="mt-4">
                                    Hair tattoo
                                </div>
                            </div>
                            <div className="flex flex-col flex-grow">
                                <div className="mt-5">
                                    Best beard
                                </div>
                                <div className="mt-5">
                                    Best fade
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full relative flex flex-col items-center mt-2 md:mt-20 px-0 xl:px-9">
                <div className="absolute inset-0 hidden sm:block how-it-works">
                </div>

                <div className="w-full relative flex flex-col items-center px-2 xl:px-0">
                    <div className="relative w-full xl:w-container-wide max-w-full mt-8 separator-container">
                        <div className="w-full flex flex-row items-center relative h-10 md:h-20 pl-8">
                            <img className="absolute left-0 h-10 md:h-20" src={Scissors}/>
                            <div className="relative flex-grow separator"/>
                        </div>
                    </div>
                </div>

                <div className="relative w-full xl:w-container max-w-full flex flex-col mt-12 md:mt-24">
                    <div className="font-GinRough uppercase text-5xl md:text-7.5xl text-brown-light px-9 xl:px-0">
                        How it Works
                    </div>
                    <div
                        className="font-DIN2014 text-base md:text-2xl md:leading-9 text-white mt-10 md:max-w-lg px-9 xl:px-0">
                        Everything happens remotely, through
                        an online form. All you need is a smartphone camera
                        and mad barber skills!
                    </div>
                    <div className="mt-24 w-full flex flex-row flex-wrap items-center justify-start text-white">
                        <img src={HowItWorks1} className="flex-grow mb-16 md:mb-8 h-auto"/>
                        <img src={HowItWorks2} className="flex-grow mb-16 md:mb-8 h-auto"/>
                    </div>
                    <div className="w-full flex flex-row flex-wrap items-center justify-start text-white">
                        <img src={HowItWorks3} className="flex-grow mb-16 h-auto"/>
                        <img src={HowItWorks4} className="flex-grow mb-16 h-auto"/>
                    </div>
                </div>
            </div>


            <div className="w-full relative flex flex-col items-center px-2 xl:px-9">
                <div className="w-full xl:w-container max-w-full flex flex-col px-7 xl:px-0">
                    <div
                        className="font-DIN2014 text-base md:text-2xl md:leading-9 text-white mt-8 md:text-left md:max-w-lg">
                        Questions? Check out our <a className="text-brown-light font-bold underline hover:no-underline"
                                                    href="https://instagram.com/barberbattleonline/?utm_source=website&utm_content=barberbattleonline.com"
                    >
                        Instagram account
                    </a> and drop us a line – we'll be happy to answer any of them 24/7.
                    </div>
                    <Button href="/signup"
                            className="w-full xl:w-96 max-w-full mt-5 md:mt-9">
                        Join now
                    </Button>
                </div>

                <div className="relative w-full xl:w-container-wide max-w-full mt-10 md:mt-20 separator-container">
                    <div className="w-full flex flex-row items-center relative h-10 md:h-20 pl-8">
                        <img className="absolute left-0 h-10 md:h-20" src={Scissors}/>
                        <div className="relative flex-grow separator"/>
                    </div>
                </div>

                <div className="w-full xl:w-container max-w-full flex flex-col px-7 xl:px-0">
                    <div className="w-full flex flex-col xl:flex-row">
                        <div className="flex flex-col mt-9 md:mt-16">
                            <div className="font-GinRough uppercase text-5xl md:text-7.5xl text-white">
                                Meet your <br className="hidden xl:inline"/>
                                judges
                            </div>
                            <div
                                className="font-DIN2014 text-base md:text-2xl md:leading-9 text-white mt-7 md:mt-14 lg:max-w-3xl xl:max-w-xs">
                                Your cut will be evaluated by our panel of the industry's finest names.
                                They will thoroughly review every haircut, ranking you and your
                                fellow competitors based on a predetermined set of rules.
                            </div>
                        </div>
                        <div className="flex-grow"/>

                        <div
                            className="flex items-center justify-between flex-col md:flex-row flex-wrap text-white mt-14 md:mt-22 mb-5 md:mb-10">
                            {jurors.map((juror, index) => (
                                <CarouselItem
                                    {...juror}
                                    isFocused={true}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
};
