import React, {Dispatch, Ref} from 'react';

import UploadIcon from '../../../assets/icons/signup/upload-icon.svg';
import SuccessIcon from '../../../assets/icons/teaser/success.svg';

import {useFileUpload} from './hooks';

export type FileUploadProps = {
    id: string,
    innerRef: Ref<HTMLDivElement>,
    title: string,
    description?: string,
    setFile: Dispatch<File | null>,
    type: 'image' | 'video' | 'optional item',
    progress: number | null,
    error?: string,
    touched: boolean,
};

export const FileUpload: React.FC<FileUploadProps> = ({innerRef, id, title, description, type, setFile, progress, error: errorValue, touched}) => {
    const {fileUrl, onFileChange, key, success} = useFileUpload(setFile, progress);
    const error = touched ? undefined : errorValue;

    return (
        <div ref={innerRef} className="w-full my-10 max-w-md lg:mx-8 relative">
            {fileUrl && progress !== null && (
                <div className="absolute left-0 right-0 top-0 z-10">
                    <div className="relative border-bronze border-t-2 transition-all duration-100"
                         style={{
                             width: `${progress}%`,
                         }}
                    />
                    <div className="text-bronze font-DIN2014 text-base font-bold text-right px-2">
                        {progress < 100 ? `${progress}%` : ''}
                    </div>
                </div>
            )}

            {fileUrl && type === 'image' && (
                <div className="h-48 w-full -mb-5 relative">
                    <div className="absolute inset-0 image-preview"
                         style={{ backgroundImage: `url('${fileUrl}')` }}
                    />
                    {progress === 100 && (
                        <div className="absolute flex items-center justify-center inset-0 z-10">
                            <img src={SuccessIcon} className="h-16"/>
                        </div>
                    )}
                </div>
            )}

            <h2 className="text-white text-2xl">
                {title}
            </h2>

            <label htmlFor={id}
                   className={
                       'border px-4 py-4 mt-4 text-brown-light font-bold flex items-center font-DIN2014 cursor-pointer' +
                       (error ? ' border-red' : ' border-brown-light')
                   }
            >
                <span>
                    {progress === 100 ? 'REPLACE' : 'UPLOAD'} {type.toUpperCase()}
                </span>
                <img
                    src={UploadIcon}
                    alt="Cloud upload icon"
                    className="ml-auto"
                />
            </label>

            <input type="file"
                   id={id}
                   onChange={onFileChange}
                   className="hidden"
                   accept={type === 'optional item' ? undefined : `${type}/*`}
            />
            {error && (
                <div className="font-DIN2014 text-red text-sm mt-1.5">
                    {error}
                </div>
            )}

            {description && (
                <div className="font-DIN2014 text-silver text-sm mt-1.5">
                    {description}
                </div>
            )}
        </div>
    );
};
