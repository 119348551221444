import axios from 'axios';

export type GoogleDriveFile = {
    id: string,
    name: string,
};

export type OnUploadProgressUpdate = (begin: number, size: number) => any;

const auth = (accessToken: string) => ({'Authorization': `Bearer ${accessToken}`});
const contentRange = (begin: number, last: number, size: number) => ({'Content-Range': `bytes ${begin}-${last}/${size}`});

export const uploadFile = async (accessToken: string, fileName: string, file: File, onProgressUpdate: OnUploadProgressUpdate) => {
    // This request initiates a resumable file upload
    let response = await axios.post(
        'https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable',
        {
            name: fileName,
            originalFilename: file.name,
            mimeType: file.type,
        },
        {
            headers: auth(accessToken),
        },
    );

    // Files are uploaded in 512 kB chunks (must be multiple of 256 kB)
    const chunkSize = 512 * 1024;
    const uploadUrl = response.headers['location'];
    const size = file.size;

    // End is either begin + chunkSize or end of file
    const computeEnd = (begin: number) => begin + Math.min(size - begin, chunkSize);
    // Last points to the last byte inside the chunk, end points to the first byte outside the chunk
    const computeLast = (end: number) => end - 1;

    // The range response header contains successfully uploaded file range
    // Regex is defined here so that it doesn't have to parse on every iteration
    // Format is `bytes=${oldBegin}-${oldLast}`
    const rangeRegex = /bytes=[0-9]+-(?<last>[0-9]+)/;

    // |-Whole-File------------|=Current=Chunk====================|------------------------------------------|
    // 0^                  begin^                            last^ ^end                                   size^
    let begin = 0;
    let end = computeEnd(begin);
    let last = computeLast(end);

    for (; ;) {
        onProgressUpdate(begin, size);

        // Binary data of currently uploaded file chunk
        const buffer = await file.slice(begin, end).arrayBuffer();

        try {
            // Upload of the current chunk
            await axios.put(
                uploadUrl,
                buffer,
                {
                    headers: {
                        ...auth(accessToken),
                        ...contentRange(begin, last, size),
                    },
                },
            );

            // 200 Indicates upload is complete
            return;
        } catch (error) {
            // 308 indicates upload is in progress
            response = error.response;

            // Get the last successfully uploaded byte
            const range = rangeRegex.exec(response.headers['range'])!.groups!;

            // Old end is begin for the new chunk
            begin = parseInt(range.last, 10) + 1;
            end = computeEnd(begin);
            last = computeLast(end);
        }
    }
};

export const listFiles = async (accessToken: string): Promise<GoogleDriveFile[]> => {
    const response = await axios.get('https://www.googleapis.com/drive/v3/files', {
        headers: auth(accessToken),
    });

    return response.data.files;
};

export const deleteFile = async (accessToken: string, fileId: string) => {
    const response = await axios.delete(`https://www.googleapis.com/drive/v3/files/${fileId}`, {
        headers: auth(accessToken),
    });

    return response.data.files;
};

export const clearDrive = async (accessToken: string) => {
    const files = await listFiles(accessToken);

    await Promise.all(
        files.map(file => deleteFile(accessToken, file.id)),
    );
};
