import React from 'react';
import Logo from '../../assets/icons/landing/logo-white.svg';
import SuccessIcon from '../../assets/icons/teaser/success.svg';
import {TextContainer} from '../ui/text-container';
import {useClearLocalStorage} from '../../hooks';

export const Success: React.FC = () => {
    useClearLocalStorage();

    return (
        <div className="min-h-screen w-full success flex flex-col items-center justify-start px-9">
            <img src={Logo} alt="Logo" className="mt-16 h-32"/>

            <h1 className="text-brown-light text-5xl mt-10">
                Thanks!
            </h1>

            <img src={SuccessIcon} alt="" className="h-16 sm:h-24 mt-16"/>

            <TextContainer>
                <p className="text-center mt-8 max-w-xl sm:text-2xl">
                    <b>Thanks for joining Barber Battle Online!</b><br/>
                    <br/>
                    Questions? Check out our <a
                    className="text-brown-light font-bold underline hover:no-underline"
                    href="https://instagram.com/barberbattleonline/?utm_source=website&utm_content=barberbattleonline.com"
                >
                    Instagram account
                </a> and drop us a line – we'll be happy to answer any of them 24/7.<br/>
                    <br/>
                    <b>Good luck!</b>
                </p>
            </TextContainer>
        </div>
);
};
