import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import './assets/scss/index.scss';

import {Checkout} from './components/checkout';
import {Canceled} from './components/canceled';
import {Success} from './components/success';
import {Landing} from './components/landing';
import {Signup} from './components/signup';
import {Teaser} from './components/teaser';
import {CodesAdmin} from './components/codes-admin';

export const App: React.FC = () => (
    <BrowserRouter>
        <Switch>
            <Route path="/canceled">
                <Canceled/>
            </Route>
            <Route path="/success">
                <Success/>
            </Route>
            <Route path="/checkout">
                <Checkout/>
            </Route>
            <Route path="/signup">
                <Signup/>
            </Route>
            <Route path="/teaser">
                <Teaser/>
            </Route>
            <Route path="/codes-admin">
                <CodesAdmin/>
            </Route>
            <Route path="/">
                <Landing/>
            </Route>
        </Switch>
    </BrowserRouter>
);
