import React from 'react';
import {DateTime} from 'luxon';

import {useTimer} from './hooks';

import TimerSvg from '../../../../assets/images/new.svg';

type TimeProps = {
    dueOnDate?: DateTime;
    leadText?: string;
    timerStyles?: string;
};

/*export const Timer: React.FC<TimeProps> = ({timerStyles, ...props}) => {
    const {dotsShown, days, hours, minutes, leadText, clockText} = useTimer(props.dueOnDate, props.leadText);
    const dotClasses = 'text-3xl sm:text-6xl tracking-timer ' + (dotsShown ? '' : 'text-transparent');

    return (
        <>
            <div className="sm:hidden text-center font-DIN2014 uppercase text-sm text-white">
                {leadText}
            </div>
            <div
                className="countdown relative flex flex-col items-center justify-center sm:justify-start sm:py-12 w-52 h-22 sm:w-108 sm:h-60 sm:mt-0 text-white"
            >
                <div className="relative font-DIN2014 uppercase sm:text-2xl hidden sm:block">
                    {leadText}
                </div>
                <div
                    className="flex flex-row justify-center sm:justify-evenly text-3xl sm:text-5xl pl-timer sm:mt-4 font-Gin text-3xl sm:text-6xl text-center uppercase">
                    {clockText ? (
                        <div className="mt-4 sm:mt-1">
                            {clockText}
                        </div>
                    ) : (
                        <>
                            <div className="flex flex-col">
                                <div className="tracking-timer">
                                    {days}
                                </div>
                                <div className="mt-3 pr-timer">
                                    <div className="text-2xs sm:text-xs font-DIN2014">
                                        Days
                                    </div>
                                </div>
                            </div>

                            <p className={dotClasses}>
                                :
                            </p>

                            <div className="flex flex-col">
                                <div className="tracking-timer">
                                    {hours}
                                </div>
                                <div className="mt-3 pr-timer">
                                    <div className="text-2xs sm:text-xs font-DIN2014">
                                        Hours
                                    </div>
                                </div>
                            </div>

                            <p className={dotClasses}>
                                :
                            </p>

                            <div className="flex flex-col">
                                <div className="tracking-timer">
                                    {minutes}
                                </div>
                                <div className="mt-3 pr-timer">
                                    <div className="text-2xs sm:text-xs font-DIN2014">
                                        Minutes
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};*/

export const Timer: React.FC<TimeProps> = () => {
    return (
        <img src={TimerSvg}/>
    );
};
