import React, {useState} from 'react';
import {CodesAdminImpl} from './impl';
import {CodesAdminAuth} from './auth';
import axios from 'axios';
import {Config} from '../../config';

export const CodesAdmin: React.FC = () => {
    const [password, setPassword] = useState('');
    const [isAuth, setIsAuth] = useState(false);

    const submit = () => {
        (async () => {
            const {data} = await axios.post(`${Config.ApiUrl}/admin/codes/auth`, {password});

            if (data.status === 'success')
                setIsAuth(true);
            else {
                alert('Invalid password');
            }
        })();
    };

    return isAuth ?
        <CodesAdminImpl
            password={password}
        /> :
        <CodesAdminAuth
            password={password}
            setPassword={setPassword}
            submit={submit}
        />;
};