import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Config} from '../../config';

type CodesAdminImplProps = {
    password: string,
};

export const CodesAdminImpl: React.FC<CodesAdminImplProps> = ({password}) => {
    const [code, setCode] = useState('');
    const [codes, setCodes] = useState<{ id: number, value: string }[]>([]);
    console.log(codes);

    const refreshCodes = async () => {
        const {data} = await axios.post(`${Config.ApiUrl}/admin/codes/all`, {password});

        if (data.data)
            setCodes(data.data);
    };

    const submitAddCode = () => {
        (async () => {
            const {data} = await axios.post(`${Config.ApiUrl}/admin/codes/add/discount`, {
                password,
                code,
            });

            if (data.status === 'success')
                await refreshCodes();
            else
                alert('Operation failed');
        })();
    };

    const submitDeleteCode = (id: number) => () => {
        (async () => {
            const {data} = await axios.post(`${Config.ApiUrl}/admin/codes/delete/discount`, {
                password,
                id,
            });

            if (data.status === 'success')
                await refreshCodes();
            else
                alert('Operation failed');
        })();
    };

    useEffect(() => {
        refreshCodes();
    }, []);

    return (
        <div className="w-full flex flex-col items-center text-white font-DIN2014 p-8">
            <div className="container flex flex-col items-stretch">
                <div className="flex flex-col items-stretch mb-4">
                    <div className="text-2xl mb-4">
                        Add code
                    </div>
                    <div className="flex flex-row items-stretch">
                        <input type="text"
                               placeholder="Code"
                               className="appearance-none border border-brown-light focus:border-white focus:outline-none bg-brown-dark p-4 text-base mr-4 flex-1 min-w-0"
                               value={code}
                               onChange={e => setCode(e.target.value)}
                        />
                        <button type="button"
                                className="border border-brown-light hover:bg-white text-white font-bold hover:text-brown-light p-4"
                                onClick={submitAddCode}
                        >
                            Submit
                        </button>
                    </div>
                </div>

                <div className="flex flex-col items-stretch">
                    <table className="border-collapse">
                        <thead>
                        <tr>
                            <th className="p-2 border border-brown-light font-bold p-2">
                                Code
                            </th>
                            <th className="p-2 border border-brown-light font-bold p-2">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {codes && codes.map(({value, id}) => (
                            <tr>
                                <td className="p-2 border border-brown-light p-2">
                                    {value}
                                </td>
                                <td className="p-2 border border-brown-light p-2">
                                    <button type="button"
                                            className="underline hover:no-underline text-brown-light font-bold"
                                            onClick={submitDeleteCode(id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};