import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {Stripe} from '@stripe/stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {AppState} from '../../store';
import {formatPrice} from './format-price';
import axios, {AxiosResponse} from 'axios';
import {Config} from '../../config';

export const PaymentForms = {
    Card: 'card',
    Paypal: 'paypal',
} as const;
export type PaymentForm = typeof PaymentForms[keyof typeof PaymentForms];

type State = {
    paymentForm: PaymentForm,
    price: string | null,
    unitAmount: number,
    currency: string,
    loading: boolean,
    error: string | null,
    stripe: Stripe | null,
};

const InitialState: State = {
    paymentForm: PaymentForms.Card,
    price: null,
    unitAmount: 0,
    currency: '',
    loading: false,
    error: null,
    stripe: null,
} as const;

export const init = createAsyncThunk(
    'checkout/init',
    async ({signupSessionId}: {signupSessionId: string}) => {
        const response = await axios.post(`${Config.ApiUrl}/config`, {signupSessionId});

        if (response.data.redirect)
            window.location.replace(response.data.redirect);
        else if(response.data.error)
            window.location.replace(`/signup?error=${encodeURIComponent(response.data.error)}`);

        const {publicKey, unitAmount, currency} = response.data;

        const stripe = await loadStripe(publicKey);

        return {
            stripe,
            unitAmount,
            currency,
        };
    },
);

export const checkout = createAsyncThunk(
    'checkout/checkout',
    async ({stripe, signupSessionId}: { stripe: Stripe, signupSessionId: string }) => {
        let response: AxiosResponse<any>;
        try {
            response = await axios.post(`${Config.ApiUrl}/create-checkout-session`, {
                quantity: 1,
                signupSessionId,
                redirectDomain: window.location.origin
            });
        } catch (error) {
            return error.response.data.message;
        }

        const {sessionId} = response.data;

        const {error} = await stripe.redirectToCheckout({sessionId});
        return error?.message;
    },
);

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: InitialState,
    reducers: {
        setPaymentForm: (state, action: PayloadAction<PaymentForm>) => {
            state.paymentForm = action.payload;
        },
    },
    extraReducers: builder =>
        builder
            .addCase(init.pending, (state) => {
                state.loading = true;
            })
            .addCase(init.fulfilled, (state, action) => {
                state.loading = false;
                state.currency = action.payload.currency;
                state.unitAmount = action.payload.unitAmount;
                state.stripe = action.payload.stripe;
                state.price = ((action.payload.unitAmount || '39') / 100).toString();
            })
            .addCase(checkout.pending, (state) => {
                state.loading = true;
            })
            .addCase(checkout.fulfilled, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            }),
});

export const checkoutReducer = checkoutSlice.reducer;

export const setPaymentForm = checkoutSlice.actions.setPaymentForm;

export const CheckoutSelector = {
    paymentForm: (state: AppState) => state.checkout.paymentForm,
    price: (state: AppState) => state.checkout.price,
    loading: (state: AppState) => state.checkout.loading,
    error: (state: AppState) => state.checkout.error,
    stripe: (state: AppState) => state.checkout.stripe,
};
