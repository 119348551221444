import {MouseEvent, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router';
import {init, isPromoCode, setField, setFile, setScrollTo, signup, SignupSelector} from './store';
import {useDispatch, useSelector} from '../../hooks';
import {FieldName} from './fields';
import {FileUploadName} from './file-uploads';

export const useSignUp = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const scrollTo = useSelector(SignupSelector.scrollTo);

    const fields = useSelector(SignupSelector.fields);
    const errors = useSelector(SignupSelector.errors);
    const touched = useSelector(SignupSelector.touched);

    const fileProgress = useSelector(SignupSelector.fileProgress);
    const fileErrors = useSelector(SignupSelector.fileErrors);
    const fileTouched = useSelector(SignupSelector.fileTouched);

    const [price, setPrice] = useState(39);

    useEffect(() => {
        dispatch(init());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const timeout = setTimeout(async () => {
            await isPromoCode(fields.promoCode);
            setPrice(Number(localStorage.getItem('price')) ?? 39);
        }, 250);

        return () => {
            clearTimeout(timeout);
        };
    }, [fields.promoCode]);

    const onSubmit = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        dispatch(signup(history));
    };

    const ref = {
        email: useRef<HTMLDivElement>(null),
        country: useRef<HTMLDivElement>(null),
        instagramProfileName: useRef<HTMLDivElement>(null),
        promoCode: useRef<HTMLDivElement>(null),

        mainPhoto: useRef<HTMLDivElement>(null),
        profileView: useRef<HTMLDivElement>(null),
        mainVideo: useRef<HTMLDivElement>(null),
        timelapse: useRef<HTMLDivElement>(null),
    } as const;

    useEffect(() => {
        if (scrollTo) {
            ref[scrollTo as keyof typeof ref].current?.scrollIntoView(true);
            dispatch(setScrollTo(undefined));
        }
    }, [scrollTo]);

    return {
        history,
        fields,
        errors,
        touched,
        fileProgress,
        fileErrors,
        fileTouched,
        price,
        ref,
        onSubmit,
        setField: (key: FieldName, value: string) => dispatch(setField({key, value})),
        setFile: (key: FileUploadName, file: File | null) => dispatch(setFile({key, file})),
    };
};
