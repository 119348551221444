export const Fields = [
    {name: 'email', placeholder: 'E-mail'},
    {name: 'country', placeholder: 'Country'},
    {name: 'instagramProfileName', placeholder: 'Instagram name'},
    {name: 'promoCode', placeholder: 'Promo code'},
] as const;

type Keys = 0 | 1 | 2 | 3;
type Item = typeof Fields[Keys];
export type FieldName = Item['name'];
