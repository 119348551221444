import {ChangeEvent, Dispatch, useEffect, useState} from 'react';

export const useFileUpload = (setFile: Dispatch<File | null>, progress: number | null) => {
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [key, setKey] = useState<number>(0);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (progress === 100) {
            timeout = setTimeout(() => {
                setKey(key + 1);
                setSuccess(true);
            }, 100);
        } else if(success) {
            timeout = setTimeout(() => setSuccess(false), 100);
        }

        return () => {
            clearTimeout(timeout);
        }
    }, [progress]);

    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files![0];

        setFile(file);

        if (fileUrl)
            URL.revokeObjectURL(fileUrl);
        setFileUrl(URL.createObjectURL(file));
    };

    return {
        fileUrl,
        onFileChange,
        key,
        success,
    };
};
