export const FileUploads = [
    {
        title: '1. Main photo (required)',
        description: 'Take a pic of your cut so that it\'s easily visible. A tilted front view works best.',
        type: 'image',
        id: 'main-photo',
        name: 'mainPhoto',
    },
    {
        title: '2. Profile view (optional)',
        description: 'With some cuts, it\'s also a good idea to upload a profile view for a better picture.',
        type: 'image',
        id: 'profile-view',
        name: 'profileView',
    },
    {
        title: '3. 360° video (optional)',
        description: 'If you feel fancy, you can also upload a 360° walkaround so that no detail escapes us.',
        type: 'video',
        id: 'main-video',
        name: 'mainVideo',
    },
    {
        title: '4. Bonus material (optional)',
        description: 'If you have something else you\'d like to share such as a timelapse video, upload it here.',
        type: 'optional item',
        id: 'timelapse',
        name: 'timelapse',
    },
] as const;

type Keys = 0 | 1 | 2 | 3;
type Item = typeof FileUploads[Keys];
export type FileUploadName = Item['name'];
