import React, {useEffect, useState} from 'react';
import LogoSimple from '../../assets/icons/logo-simple.svg';
import Logo from '../../assets/icons/landing/logo-white.svg';
import {useIsDesktop} from '../../hooks';
import BulleitLogo from '../../assets/images/logo-bulleit.svg';
import BarberLogoSimple from '../../assets/icons/logo-simple.svg';
import {Button} from './button';

type HeaderProps = {
    opacity: number;
    showJoinButton?: boolean;
};

export const Header: React.FC<HeaderProps> = ({opacity, showJoinButton}) => {
    const [scrollPos, setScrollPos] = useState(0);

    useEffect(() => {
        const handleScroll = () => setScrollPos(window.pageYOffset);

        window.addEventListener('scroll', handleScroll, {passive: true});
        return () => window.removeEventListener('scroll', handleScroll);
    }, [setScrollPos]);


    return (
        <div
            className="fixed -top-0.5 left-0 top-0 right-0 w-full h-24 md:h-32 bg-brown-dark z-20 flex xl:hidden flex-row pt-1 px-9 items-center justify-between transition duration-300 ease-in header"
            style={{opacity: scrollPos > 32 ? 1 : scrollPos / 32}}
        >
            <img className="h-12" src={BarberLogoSimple}/>
            <img className=" h-24 md:h-32" src={BulleitLogo}/>
        </div>
    );
};
